// JamatkhanaCard.js

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, Button, Modal, Toast, ToastContainer } from 'react-bootstrap'
import JamatkhanaDetails from './JamatkhanaDetails'
import slugify from 'slugify';

const JamatkhanaCard = ({ jamatkhana, open }) => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [showToast, setShowToast] = useState(false);
  
  useEffect(() => {
    if (open) {
      setShowModal(true);
    }
  }, [open]);

  const handleOpenModal = () => {
    setShowModal(true);

    const url = `/${jamatkhana.id}/${slugify(jamatkhana.name, { lower: true, strict: true })}`;
    navigate(url);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    navigate('/');
  };

  const handleEditButton = () => {
    // TODO add url parameters to auto-complete form fields
    navigate('/edit-form');
  }

  const handleSharePage = () => {
    if (navigator.share) {
      navigator.share({
        title: `${jamatkhana.name} Jamatkhana`,
        text: `Details: ${jamatkhana.name} Jamatkhana in ${jamatkhana.city}, ${jamatkhana.state}`,
        url: window.location.href
      })
      .catch((error) => console.error('Error sharing'))
    } else {
      const pageUrl = window.location.href;
      navigator.clipboard.writeText(pageUrl).then(() => {
        // Handle success (e.g., show a success message)
        setShowToast(true);
        console.log('Link copied to clipboard:', pageUrl);
      }).catch((error) => {
        // Handle error (e.g., show an error message)
        console.error('Failed to copy link to clipboard:', error);
      });
    }
  }

  const handleToastClose = () => setShowToast(false);
  
  return (
    <>
    <Card className="mb-4" style={{ height: '200px' }}>
        {/* Display image, if available */}
        {jamatkhana.imageUrl && 
            <div style={{ position: 'relative' }} className='m-0 card-image-overlay'>
              <img src={jamatkhana.imageUrl} alt={jamatkhana.name} style={{ width: '100%', height: '200px', objectFit: 'cover' }} />
              <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'white', opacity: '75%'}}></div>
              <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', color: 'black', textAlign: 'center' }}>
                <h3>{jamatkhana.name} Jamatkhana</h3>
                <p>{jamatkhana.city}, {jamatkhana.state}</p>
                <Button variant="primary" className='my-0 py-1' onClick={handleOpenModal}>More Info</Button>{' '}
              </div>
            </div>
        }
        
        {!jamatkhana.imageUrl && 
        <Card.Body className='d-flex justify-content-center align-items-center'>
          <Card.Text>
            <Card.Title className='mb-0'><h3>{jamatkhana.name} Jamatkhana</h3></Card.Title>
            <p className='mt-0'>{jamatkhana.city}, {jamatkhana.state}</p>
            <Button variant="primary" className='py-1 px-2 border-0 rounded' onClick={handleOpenModal}>More Info</Button>{' '}
          </Card.Text>
        </Card.Body>
        }
          
        </Card>
        
        {/* Modal for additional details */}
        <Modal show={showModal} onHide={handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>{jamatkhana.name} Jamatkhana</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <JamatkhanaDetails jamatkhana={jamatkhana} />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-primary" onClick={handleSharePage}>
              Share
            </Button>
            <div className="ms-auto">
              <p className='align-left'>
                <Button variant='link' onClick={handleEditButton} className='p-0'> Something look wrong?</Button>
              </p>
            </div>
          </Modal.Footer>
        </Modal>
        {/* Bootstrap Toast component */}
        <ToastContainer className="p-3" position='bottom-end' style={{ zIndex: 1060 }}>
          <Toast show={showToast} onClose={handleToastClose} delay={3000} autohide>
            <Toast.Header closeButton={false}>
              <strong className="mr-auto">Success</strong>
            </Toast.Header>
          <Toast.Body>{`Link to ${jamatkhana.name} Jamatkhana has been copied!`}</Toast.Body>
        </Toast>
        </ToastContainer>
      </>
  );
};

export default JamatkhanaCard;
