import React, { useState, useEffect } from 'react';
import { Row, Col, Container } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import JamatkhanaCard from './JamatkhanaCard';

const Home = ({results, open, loading}) => {
    const [searchQuery, setSearchQuery] = useState('');

    const filteredResults = results.filter(
      (jamatkhana) =>
        jamatkhana.id.toLowerCase().includes(searchQuery.toLowerCase()) ||
        jamatkhana.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        jamatkhana.city.toLowerCase().includes(searchQuery.toLowerCase()) ||
        jamatkhana.state.toLowerCase().includes(searchQuery.toLowerCase()) ||
        jamatkhana.zipCode.toLowerCase().includes(searchQuery.toLowerCase())
    );

    useEffect(() => {
      if (open !== null) {
        const selectedJamatkhana = results.find((jamatkhana) => jamatkhana.id === open);
        const jamatkhanaName = selectedJamatkhana ? selectedJamatkhana.name : 'Results';
        document.title = `${jamatkhanaName} | Jamatkhanas.com`;
      } else {
        document.title = 'Jamatkhanas.com'
      }
    });
  
    return (
        <> 
        <div className="App">
            <div className='sticky-top pt-4 pb-2 text-primary mb-4 header-container'>
                <h1 className="text-center fw-bold display-2 header-branding">Jamatkhanas.com</h1>
              <Container>
                <div className='custom-search-bar'>
                  <FontAwesomeIcon icon={faSearch} className='custom-search-icon' />
                  <input 
                    type="text" 
                    placeholder="Find a Jamatkhana by name, city, or zip" 
                    className="form-control custom-search-field"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />
                </div>
              </Container>  
            </div>
            <Container fluid className='mb-4'>
                {loading ? (
                  <>
                    <Container className='d-flex justify-content-center mt-4'>
                      <div className='spinner-border text-light text-center' role='status'>
                        <span className='sr-only'></span>
                      </div>
                    </Container>
                  </>
                ) : (
                  <Container>
                    <Row className='text-center'>
                      {filteredResults.map((jamatkhana) => (
                        <Col xs={10} sm={8} md={6} lg={4} xl={3} key={jamatkhana.id} className="mx-auto">
                          <JamatkhanaCard jamatkhana={jamatkhana} open={jamatkhana.id === open} />
                        </Col>
                      ))}
                    </Row>
                  </Container>
                )}
            </Container>
          </div>
    </>
        
    )
}

export default Home;