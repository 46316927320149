import React, { useState, useEffect } from 'react';
import { Alert, Card, Form, Button } from 'react-bootstrap';

const EditForm = ({ results }) => {
    
  const [formData, setFormData] = useState({
    yourName: '',
    yourContact: '',
    jamatkhanaName: '',
    updateAddress: '',
    updateCity: '',
    updateState: '',
    updateZip: '',
    updateDuaTimes: '',
    updateAdditionalInfo: '',
  });
  const [alert, setAlert] = useState(null);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Make the API call
      console.log(formData)
      const response = await fetch('https://jamatkhanas.com/api/edit_form.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      // Handle the response
      if (response.ok) {
        const result = await response.json();
        
        // Show a Bootstrap alert based on the response
        setAlert(result.success ? (
          <Alert variant="success">
            {result.message}
          </Alert>
        ) : (
          <Alert variant="danger">
            Error: {result.message}
          </Alert>
        ));
      } else {
        console.error('Error submitting the form');
      }
    } catch (error) {
      console.error('Error:', error);
    }

    // Clear the form after submission
    setFormData({
      yourName: '',
      yourContact: '',
      jamatkhanaName: '',
      updateAddress: '',
      updateCity: '',
      updateState: '',
      updateZip: '',
      updateDuaTimes: '',
      updateAdditionalInfo: '',
    });
  };

  useEffect(() => {
      document.title = 'Suggestion Form | Jamatkhanas.com';
  });

  return (
    <div className="mx-4 d-flex align-items-center justify-content-center" style={{ height: '100vh' }}>
      <Card style={{ width: '400px' }}>
        <Card.Body>
          <h1 className="text-center mb-4">Edit Jamatkhana Information</h1>
          {alert && alert}
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="yourName">
              <Form.Label>Your Name:</Form.Label>
              <Form.Control type="text" name="yourName" value={formData.yourName} onChange={handleChange} required />
            </Form.Group>

            <Form.Group controlId="yourContact">
              <Form.Label>Your Contact (phone or email):</Form.Label>
              <Form.Control type="text" name="yourContact" value={formData.yourContact} onChange={handleChange} required />
            </Form.Group>

            <Form.Group controlId="jamatkhanaName">
                <Form.Label>Jamatkhana Name:</Form.Label>
                <Form.Select name="jamatkhanaName" value={formData.jamatkhanaName} onChange={handleChange} required>
                    <option disabled value="">Select Jamatkhana</option>
                    {results.map((jamatkhana) => (
                    <option key={jamatkhana.id} value={`${jamatkhana.id}: ${jamatkhana.name}`}>
                        {jamatkhana.name}
                    </option>
                    ))}
                </Form.Select>
            </Form.Group>


            
            <Form.Group controlId="updateAddress">
              <Form.Label>Update Jamatkhana Address:</Form.Label>
              <Form.Control type="text" name="updateAddress" value={formData.updateAddress} onChange={handleChange} />
            </Form.Group>

            <Form.Group controlId="updateCity">
              <Form.Label>Update Jamatkhana City:</Form.Label>
              <Form.Control type="text" name="updateCity" value={formData.updateCity} onChange={handleChange} />
            </Form.Group>

            <Form.Group controlId="updateState">
              <Form.Label>Update Jamatkhana State:</Form.Label>
              <Form.Control type="text" name="updateState" value={formData.updateState} onChange={handleChange} />
            </Form.Group>

            <Form.Group controlId="updateZip">
              <Form.Label>Update Jamatkhana Zip:</Form.Label>
              <Form.Control type="text" name="updateZip" value={formData.updateZip} onChange={handleChange} />
            </Form.Group>

            <Form.Group controlId="updateDuaTimes">
              <Form.Label>Update Jamatkhana Dua Times:</Form.Label>
              <Form.Control as="textarea" name="updateDuaTimes" value={formData.updateDuaTimes} onChange={handleChange} />
            </Form.Group>

            <Form.Group controlId="updateAdditionalInfo">
              <Form.Label>Update Jamatkhana Additional Information:</Form.Label>
              <Form.Control as="textarea" name="updateAdditionalInfo" value={formData.updateAdditionalInfo} onChange={handleChange} />
            </Form.Group>

            <Button type="submit" className="w-100 mt-3">
              Submit
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </div>
  );
};

export default EditForm;
