import React, { useState } from 'react';
import { Button, Row, Col } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import ReactMarkdown from 'react-markdown';
import fallbackImage from './fallback.png'
const JamatkhanaDetails= ({ jamatkhana }) => {
  const [showMoreTimes, setShowMoreTimes] = useState(false);

    const openGoogleMaps = () => {
        const addressQuery = `${jamatkhana.address}, ${jamatkhana.city}, ${jamatkhana.state} ${jamatkhana.zipCode}`;
        const googleMapsURL = `https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(addressQuery)}`;
        window.open(googleMapsURL, '_blank');
      };
    
      const getTodayEveningDuaTime = () => {
        const today = new Date().getDay();
        switch (today) {
          case 0: // Sunday
            return jamatkhana?.duaTime?.sunday || jamatkhana?.duaTime?.weekend || "Sunday Closed or Unknown";
          case 1: // Monday
            return jamatkhana?.duaTime?.monday || jamatkhana?.duaTime?.weekday || "Monday Closed or Unknown";
          case 2: // Tuesday
            return jamatkhana?.duaTime?.tuesday || jamatkhana?.duaTime?.weekday || "Tuesday Closed or Unknown";
          case 3: // Wednesday
            return jamatkhana?.duaTime?.wednesday || jamatkhana?.duaTime?.weekday || "Wednesday Closed or Unknown";
          case 4: // Thursday
            return jamatkhana.duaTime?.thursday || jamatkhana?.duaTime?.weekday || "Thursday Closed or Unknown"
          case 5: // Friday
            return jamatkhana?.duaTime?.friday || "Friday Closed or Unknown";
          case 6: // Saturday
            return jamatkhana?.duaTime?.saturday || jamatkhana?.duaTime?.weekend || "Saturday Closed or Unknown";
          default:
            return "Closed or Unknown";
        }
      };
    
      const handleToggleMoreTimes = () => {
        setShowMoreTimes(!showMoreTimes);
      }
    
      const renderDuaTimesTable = () => {
        const duaTime = jamatkhana?.duaTime;

        if (!duaTime) {
          return <p className='text-danger'>Additional Times Unavailable</p>;
        }

        const order = [
          'weekday',
          'monday',
          'tuesday',
          'wednesday',
          'thursday',
          'friday',
          'weekend',
          'saturday',
          'sunday',
          'morning',
        ];
      
        // Get keys and sort them based on the order array
        const days = Object.keys(duaTime).sort((a, b) => {
          const indexA = order.indexOf(a.toLowerCase());
          const indexB = order.indexOf(b.toLowerCase());
      
          if (indexA === -1) return 1; // If not found in order array, move to the end
          if (indexB === -1) return -1; // If not found in order array, move to the end
      
          return indexA - indexB; // Sort based on the order array
        });
    
        return (
          <div className="d-flex flex-column">
            {days.map((day) => (
              jamatkhana.duaTime[day] !== 'Closed' && (
                <div key={day} className="d-flex border rounded mb-2">
                  <div className="flex-grow-1 p-2">
                    <p className="font-weight-bold">{day.charAt(0).toUpperCase() + day.slice(1)}</p>
                  </div>
                  <div className="p-2">
                    <p>{jamatkhana.duaTime[day]}</p>
                  </div>
                </div>
              )
            ))}
          </div>
        )
      }

    return (
        <> 
        <div style={{ position: 'relative' }} className='my-2'>
            <img src={jamatkhana.imageUrl || fallbackImage} alt={jamatkhana.name} style={{ width: '100%', height: '200px' }} />
            <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}></div>
            <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', color: 'white', textAlign: 'center' }}>
              <h3>{jamatkhana.name} Jamatkhana</h3>
              <p>{jamatkhana.city}, {jamatkhana.state}</p>
            </div>
          </div>
          
          <h4 className='mt-4 mb-2 fw-bold text-jamatkhana-blue'>Address</h4>
          <Row className="justify-content-center">
          <Col sm={8}>
            <p className="m-0 p-0">{jamatkhana.address}</p>
            <p>{jamatkhana.city}, {jamatkhana.state} {jamatkhana.zipCode}</p>
          </Col>
          <Col sm={4}>
            <Button variant='primary' onClick={openGoogleMaps}>Get Directions</Button>
          </Col>
        </Row>

        <h4 className='mt-4 mb-2 fw-bold text-jamatkhana-blue'>Dua Time</h4>
        <p className='m-0 text-muted'><small>Today's Morning Dua Time:</small></p>
        <p><span className='m-0'>{jamatkhana.duaTime?.morning || 'Mornings Closed or Unknown'}</span></p>
        <p className='m-0 text-muted'><small>Today's Evening Dua Time:</small></p>
        <p><span className='m-0'>{getTodayEveningDuaTime()}</span></p>
        
        <Button variant='link' className='p-0'>
          <span className="see-more-times" onClick={handleToggleMoreTimes}>
            {showMoreTimes ? 'See Fewer Dua Times' : 'See More Dua Times'} <FontAwesomeIcon icon={showMoreTimes ? faChevronUp : faChevronDown} />
          </span>
        </Button>
        
                
        {showMoreTimes && (
      <div>
        <p className='fw-bold mt-2'>Dua Times for {jamatkhana.name} Jamatkhana:</p>
        {renderDuaTimesTable()}
      </div>
    )}

    {jamatkhana.additionalInfo && (
        <div>
            <h4 className='mt-4 mb-2 fw-bold text-jamatkhana-blue'>Additional Information</h4>
            <ReactMarkdown>{jamatkhana.additionalInfo}</ReactMarkdown>
        </div>
    )}

    </>
        
    )
}

export default JamatkhanaDetails;