// api.js

  // Function to fetch Jamatkhanas from a real API
  export const fetchJamatkhanas = async () => {
    try {
      const t = new Date().toISOString();
      const apiUrl = `https://jamatkhanas.com/api/?t=${t}`;
      console.log(apiUrl);
      const response = await fetch(apiUrl); // Replace with your API endpoint
      if (!response.ok) {
        throw new Error('Failed to fetch Jamatkhanas');
      }

      const data = await response.json();
      
      // Loop through each array element and decode duaTime
      const decodedData = data.map((element) => {
        if (element.duaTime && typeof element.duaTime === 'string') {
          element.duaTime = JSON.parse(element.duaTime);
        }
        return element;
      });

      return decodedData; // Assuming the response is in JSON format
    } catch (error) {
      console.error('Error fetching Jamatkhanas:', error.message);
      throw error;
    }
  };

  