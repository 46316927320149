import React, { useState, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import './App.scss';
import slugify from 'slugify';

import Home from './Home';
import EditForm from './EditForm';
import { fetchJamatkhanas } from './api';

const App = () => {
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchJamatkhanasData = async () => {
      const data = await fetchJamatkhanas();
      setResults(data);
      setLoading(false);
    };

    fetchJamatkhanasData();
  }, []);

  return (
    
        <Routes>
          <Route path="/" element={
            <Home results={results} loading={loading}/>
          } />

          <Route path="/edit-form" element={
            <EditForm results={results}/>
          } />
          
          {results.map((jamatkhana) => (
              <Route
                key={jamatkhana.id}
                path={`/${jamatkhana.id}/${slugify(jamatkhana.name, { lower: true, strict: true})}`}
                element={<Home results={results} open={jamatkhana.id} loading={loading}/>}
              />
          ))}

        </Routes>
     
  );

};

export default App;
